<template>
    <modal ref="modalCrearMedidaVolumetrica" :cargando="loading" :titulo="`${editing ? 'Editar' : 'Crear'} Medida volumétrica`" no-aceptar adicional="Guardar" @adicional="handle()">
        <ValidationObserver ref="form">
            <div class="row mx-0">
                <ValidationProvider v-slot="{errors}" rules="required|max:30" name="nombre" vid="nombre" class="col-8">
                    <p class="text-general f-14 pl-3">Nombre de la medida</p>
                    <el-input v-model="model.nombre" placeholder="Nombre" class="w-100" size="small" show-word-limit maxlength="30" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
                <ValidationProvider v-slot="{errors}" rules="required|numeric" name="equivalencia" vid="volumen" class="col">
                    <p class="text-general f-14 pl-3">Equivalencia</p>
                    <el-input v-model="model.volumen" placeholder="Valor" class="w-100" size="small" show-word-limit maxlength="8" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
                <ValidationProvider v-slot="{errors}" rules="required|max:350" name="descripcion" vid="descripcion" class="col-12 mt-4">
                    <p class="text-general f-14 pl-3">Descripción</p>
                    <el-input v-model="model.descripcion" type="textarea" :rows="5" placeholder="Descripción" class="w-100" show-word-limit maxlength="350" />
                    <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                </ValidationProvider>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import pesoVolumetrico from '~/services/configurar/admin/pesoVolumetrico'
export default {
    data(){
        return {
            model: {
                nombre: '',
                descripcion: '',
                volumen: ''
            },
            editing: false,
            id: 0,
            loading: false,
        }
    },
    methods: {
        toggle(){
            this.limpiar()
            this.$refs.modalCrearMedidaVolumetrica.toggle();
        },
        editar(medida){
            const model = _.cloneDeep(medida)
            this.model.nombre = model.nombre
            this.model.descripcion = model.descripcion
            this.model.volumen = model.volumen
            this.editing = true
            this.id = model.id
            this.$refs.modalCrearMedidaVolumetrica.toggle();
        },
        handle(){
            if(this.editing){
                this.actualizar()
            } else {
                this.guardar()
            }
        },
        async guardar(){
            try {
                this.loading = true
                const valid = await this.$refs.form.validate()
                if(!valid) return this.notificacion('', 'Hay campos requeridos', 'error')

                const { data } = await pesoVolumetrico.crearVolumen(this.model)
                if(data.exito){
                    this.notificacion('', 'Peso creado correctamente', 'success')
                    this.limpiar()
                    this.$emit('saved')
                    this.$refs.modalCrearMedidaVolumetrica.toggle();
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false

            }
        },
        async actualizar(){
            try {
                this.loading = true
                const valid = await this.$refs.form.validate()
                if(!valid) return this.notificacion('', 'Hay campos requeridos', 'error')

                const { data } = await pesoVolumetrico.editarVolumen(this.id, this.model)
                if(data.exito){
                    this.notificacion('', 'Peso actualizado correctamente', 'success')
                    this.limpiar()
                    this.$emit('saved')
                    this.$refs.modalCrearMedidaVolumetrica.toggle();
                }
            } catch (error){
                this.error_catch(error)
            } finally {
                this.loading = false
            }
        },
        limpiar(){
            this.model = {
                nombre: '',
                descripcion: '',
                volumen: ''
            }
            this.editing = false
            this.id = 0
            this.$refs.form.reset()
        }
    }
}
</script>